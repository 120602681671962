<template>
  <section class="landing-page">
      
      
      
       
        
      <div  class="horizontal-menu" style="position: fixed;z-index: 1030; background-color: rgb(26,20,46,0.98); ">
 
            <nav class="navbar   navbar-expand-lg">
                <div class="container">
                
                    <router-link class="navbar-brand brand-logo m-0 p-0" to="/">
                        <a class="navbar-brand m-0 p-0 mb-2" ><img style="max-width:130px; height: 20px;" src="resource/logo/logo_white.png" alt=""></a> 
                   </router-link>
                    
                    <button class="navbar-toggler navbar-toggler-right align-self-center" 
                            @click="toggleMobileSidebar()">
                      <span class="mdi mdi-menu" style="color:white;"></span>
                    </button>
                
                
         
         
                 
                <!--collapse navbar-collapse justify-content-end -->
                  <div class="collapse navbar-collapse justify-content-end "  >
                     
                                     
                           <ul class="navbar-nav"  >        <!--navbar-nav-->         
 <!--
                             <li class="nav-item"   >
                               <a class="nav-link" href="#middle" style="font-size: 1.3rem; color: white; ">Как считать QR-код</a>
                             </li>-->
                           <!--   <li class="nav-item"   >
                               <a class="nav-link" href="#middle" style="font-size: 1.3rem; color: white; ">Контакты</a>
                             </li>
                           
                           <!--
                             <li class="nav-item"   >
                                <a  href="#middle" class="nav-link testtest mr-5">Менеджер</a> 
                             </li>-->
                             
                             
                             
                             
                                         <!--  v-if="is_logined"             
                                    <li class="nav-item"   >
                                      <router-link class="" to="/landing/register_2">
                                       <button class="btn btn-danger btn-sm mr-3 mt-2 mb-2">Кабинет</button>
                                     </router-link>
                                    </li>-->  
                                   

                                  
                                   
                            
                               

                           </ul>
                               
                    </div>
                
                
                
          
                
                </div>
            </nav>
          
      </div>   
      
      
      
      
      
    <div class="banner-wrapper">
        
        
      <div class="container">
        <div class="row ">
         
            
            
          
            
            
        </div>
        <div class="row py-sm-5 py-0">
          <div class="col-lg-7 banner-content" style="padding: 135px 15px 30px 15px;">
            <h1 class="mr-2 text-white"> Сервис - QR ЛВР  </h1>
            <h3 class="font-weight-light text-white pt-2 pb-5">Сообщи о проблеме клининга просто считав QR код!</h3>
                                
            <!--
                                <router-link class="" to="/forms/addqr?objcode=qw">
                                       <button class="btn btn-danger btn-sm mr-3 mt-2 mb-2">Внутрь в кабинет</button>
                                     </router-link>-->
            <div class="my-5">
             
              <!--  
             <router-link class="" to="/landing/register_2">
                       <button class="btn btn-danger btn-lg ml-2 mb-3">Регистрация</button>
             </router-link>
                
              <router-link class="" to="/mywidgets/">
                <button class="btn btn-danger btn-lg ml-2 mb-3">Личный Кабинет</button>
              </router-link>   
                -->
                
                
             <!--   
              <router-link class="" to="/lkchild/dash/">
                     <button class="btn btn-danger btn-lg ml-2 mb-3" v-on:click="testclick">Подробнее</button>
               </router-link>   
                -->
                
                
            </div>
          <!--  <p style="color:#46c35f">Сделать разлогин и чтобы он работал из других вкладок - там функция event heandler</p>
          -->
          </div>
        </div>
      </div>
    </div>
      
      
      
      <!--       
   <div id="middle"  class="details-wrapper">
       
       
       
       
     
       
      <div class="container">
     
       
     
        <div class="row pt-5">
          <div class="col-md-12 pb-5 text-center">
            <h1>Система позволит</h1>
          </div>
          <div class="feature-list pt-5">
            <div class="row feature-list-row text-center border-bottom">
              <div class="col-lg-3 feature-list-item border-right">
                <i class="mdi mdi-receipt"></i>
                <h3>Сообщить о проблеме с уборкой</h3>
                <p class="feature-description">Мы неазмедлительно приступим к их странению!</p>
              </div>
              <div class="col-lg-3 feature-list-item border-right">
                <i class="mdi mdi-target"></i>
                <h3>Оценить качество уборки</h3>
                <p class="feature-description">Как негативно, так и позитивно</p>
              </div>
              <div class="col-lg-3 feature-list-item border-right">
                <i class="mdi mdi-cellphone"></i>
                <h3>Бесплатно</h3>
                <p class="feature-description">Просто пульзуйтесь системой!</p>
              </div>
              <div class="col-lg-3 feature-list-item">
                <i class="mdi mdi-emoticon-happy-outline"></i>
                <h3>Оставить заявку менеджеру</h3>
                <p class="feature-description">Для связи с менеджером, просто оставьте заявку!</p>
              </div>
            </div>
            <div class="row feature-list-row text-center">
              
              <div class="col-lg-3 feature-list-item border-right">
                
              </div>  
                
              <div class="col-lg-3 feature-list-item border-right">
                <i class="mdi mdi-monitor"></i>
                <h3>Оперативность</h3>
                <p class="feature-description">Решение проблем еще никогда не было так быстро</p>
              </div>
                
              <div class="col-lg-3 feature-list-item border-right">
                <i class="mdi mdi-select-compare"></i>
                <h3>Отчеты</h3>
                <p class="feature-description">Следи за ситуацией</p>
              </div>  
                
              
              <div class="col-lg-3 feature-list-item">
                 
              </div>
                
                
            </div>
          </div>
            
            <div class="col-md-12 pb-5 mt-4 text-center">
            <h2 class="mt-4">Попробуй! Это просто!</h2>
          </div>
            
            
        </div>
     
      
      </div>
       
       
      
       
       
       
    </div>     -->
      
    <footer style="padding: 35px 0 30px 0; background-color: #212529;">
        
        <!--
      <div class="border-bottom">
        <div class="container">
          <div class="row">
            <div class="col-lg-4">
                <img class="img-fluid" style="max-width:100px; height: 45px;"  src="resource/logo/logo_white.svg" alt="">
              <div class="d-flex pt-5 pb-5"> 
                <div class="social-icons bg-info text-white"><span class="mdi mdi-facebook"></span></div>
                <div class="social-icons bg-secondary text-white"><span class="mdi mdi-pinterest"></span></div>
                <div class="social-icons bg-danger text-white"><span class="mdi mdi-dribbble"></span></div>
                <div class="social-icons bg-info text-white"><span class="mdi mdi-twitter"></span></div>
              </div>
            </div>
            <div class="col-lg-5">
              <div class="d-flex">
                <i class="mdi mdi-cellphone-android"></i>
                <h6 class="font-weight-normal">(0000) 1234 5678</h6>
              </div>
              <div class="d-flex">
                <i class="mdi mdi-settings"></i>
                <h6 class="font-weight-normal">support@staradmin.org</h6>
              </div>
              <ul class="nav mt-3">
                <li class="nav-item">
                  <a class="nav-link pl-0" href="javascript:void(0);">Demos</a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" href="javascript:void(0);">Features</a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" href="javascript:void(0);">Support</a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" href="javascript:void(0);">Documentation</a>
                </li>
              </ul>
            </div>
            <div class="col-lg-3">
              <h3 class="text-white">Newsletter</h3>
              <p>To get the latest news from us please subscribe your email.</p>
              <div class="mt-3">
                <input type="email" class="form-control mb-2" placeholder="Enter your email"> </div>
            </div>
          </div>
        </div>
      </div>
        -->
      <div class="container">
        <div class="row">
          <div class="col-sm-8">
              <h5 class="m-0 mt-1 mb-2"><a style="color: orange;" href="javascript:void(0);">ЛВР Сервис</a> <font style="color: white;">Copyright © 2022 Все права защищены.</font></h5>
          </div>
          <div class="col-sm-4">
               <img class="img-fluid mt-1" style="max-width:130px; height: 20px;"  src="resource/logo/logo_white.png" alt="">
              
          </div>
        </div>
      </div>
    </footer>
  </section>
</template>

<script>
export default {
  name: 'landing-page',
  

  mounted() {
     
     console.log("mounted landing...");
     
                if (this.$metrika)
                {
                  //  this.$metrika.hit("/");
                  //  console.log(this.$metrika); //чё в объекте то из функций !!! там есть и друние  setUserID userParams для более крутой аналитики и сегментированя на основе импорта csv
                //ym(69638053,'reachGoal','text_help_click')
                //this.$metrika.reachGoal('text_help_click');
                //this.$metrika.reachGoal('video_help_click');
                //this.$metrika.ym(69638053,'reachGoal','video_help_click');
                    console.log("metrika fin");
                }
      
  },
  
  
  methods: {
      
         button_login_click(){
             console.log('button_login_click');             
         },
         
          button_logout_click(){
             
             this.$root.users_data_clear();
             window.location.reload();
             
         },
         
    
    testclick: function () {
        
      
        
       localStorage.setItem('ggg', Math.floor(Math.random() * Math.floor(500)));
       console.log(localStorage.getItem('ggg'));
       
       localStorage.removeItem('ggg');
        
    },
    
    toggleMobileSidebar: () => {
      document
        .querySelector(".navbar-collapse")
        .classList.toggle("collapse");
    },
    
    
    
    
   },
   
   
   computed: {
     
        is_logined () {
           return true;//this.$root.is_logined();
                //var is_logined=this.$root.is_logined();
            /*  if  (is_logined)
              {
                  return true;
              }
              else
              { 
                  //console.log(this.$router.currentRoute.path);
                  return false;
              }*/
          
        },
   
   },
}



</script>


<style scoped> 
    
    @media (max-width: 400px){
        h1 { font-size:200%!important;}   
        h2 { font-size:175%!important;}   
        h3 { font-size:150%!important;}   
        
    }
</style>
